@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  position: relative;
  font-family: 'Poppins', sans-serif;
}

body {
  background: none;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.3;
  z-index: -1;
}
